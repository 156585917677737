<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-2">
        Determine the appropriate <b>conjugate base</b> for each of the compounds listed below.
      </p>

      <p class="mb-n3">
        a)
        <chemical-latex content="HPO4^{2-}(aq)" />
      </p>

      <v-radio-group v-model="inputs.input1" :disabled="!allowEditing" class="pl-8">
        <div v-for="option in options1" :key="option.value">
          <v-radio class="d-inline-block mb-0" :value="option.value" />
          <stemble-latex :content="option.base" />
        </div>
      </v-radio-group>

      <p class="mb-n3">
        b)
        <chemical-latex content="H2PO4^{-}(aq)" />
      </p>

      <v-radio-group v-model="inputs.input2" :disabled="!allowEditing" class="pl-8">
        <div v-for="option in options2" :key="option.value">
          <v-radio class="d-inline-block mb-0" :value="option.value" />
          <stemble-latex :content="option.base" />
        </div>
      </v-radio-group>

      <p class="mb-n3">
        c)
        <chemical-latex content="H2S(aq)" />
      </p>

      <v-radio-group v-model="inputs.input3" :disabled="!allowEditing" class="pl-8">
        <div v-for="option in options3" :key="option.value">
          <v-radio class="d-inline-block mb-0" :value="option.value" />
          <stemble-latex :content="option.base" />
        </div>
      </v-radio-group>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';
import ChemicalLatex from '../displayers/ChemicalLatex';

export default {
  name: 'Question132',
  components: {
    StembleLatex,
    ChemicalLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: null,
        input2: null,
        input3: null,
      },
      options1: [
        {base: '$\\ce{PO4^{-}}$', value: '1'},
        {base: '$\\ce{PO4^{3-}}$', value: '2'},
        {base: '$\\ce{H2PO4^{-}}$', value: '3'},
        {base: '$\\ce{H3PO4}$', value: '4'},
      ],
      options2: [
        {base: '$\\ce{H3PO4}$', value: '1'},
        {base: '$\\ce{H3PO4^+}$', value: '2'},
        {base: '$\\ce{HPO4^{2-}}$', value: '3'},
        {base: '$\\ce{HPO4}$', value: '4'},
      ],
      options3: [
        {base: '$\\ce{H3S^+}$', value: '1'},
        {base: '$\\ce{H3S^-}$', value: '2'},
        {base: '$\\ce{HS^-}$', value: '3'},
        {base: '$\\ce{HS^+}$', value: '4'},
      ],
    };
  },
};
</script>
